<template>
  <AppLayout>
    <main>
      <div class="page-header pb-10 page-header-dark bg-primary">
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>Marine Insurance</span>
            </h1>
            <div class="page-header-subtitle ml-2">Buy Marine Insurance</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">Buy Marine Insurance</div>
          <div class="card-body">
            <div class="levels mb-3">
              <div class="row">
                <div :class="[steps == 4 ? 'col-md-3' : 'col-md-4']">
                  <span :class="[steps >= 1 ? 'text-blue' : '']"
                    >1. Insured Information</span
                  >
                  <div
                    class="line w-100 bg-light py-1 my-1"
                    :class="[steps >= 1 ? 'bg-blue' : '']"
                  ></div>
                </div>
                <div :class="[steps == 4 ? 'col-md-3' : 'col-md-4']">
                  <span :class="[steps >= 2 ? 'text-blue' : '']"
                    >2. Shipment/Policy Information</span
                  >
                  <div
                    :class="[steps >= 2 ? 'bg-blue' : '']"
                    class="line w-100 bg-light py-1 my-1"
                  ></div>
                </div>
                <div :class="[steps == 4 ? 'col-md-3' : 'col-md-4']">
                  <span :class="[steps >= 3 ? 'text-blue' : '']"
                    >3. Preview</span
                  >
                  <div
                    :class="[steps >= 3 ? 'bg-blue' : '']"
                    class="line w-100 bg-light py-1 my-1"
                  ></div>
                </div>
                <div v-if="steps == 4" class="col-md-3">
                  <span :class="[steps == 4 ? 'text-blue' : '']"
                    >4. Payment</span
                  >
                  <div
                    :class="[steps == 4 ? 'bg-blue' : '']"
                    class="line w-100 bg-light py-1 my-1"
                  ></div>
                </div>
              </div>
            </div>
            <div class="form">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<style scoped>
.bg-red {
  background-color: #990100;
}
.text-red {
  color: #990100;
}
</style>


<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "@/stores/marine";
import { useAdminStore } from "@/stores/admin";
import { useMainStore } from "@/stores/main";

export default {
  name: "Buy Marine",
  components: { AppLayout },
  data() {
    return {
      state: {},
      lga: {},
      marketeer: {},
      currency: {},
      data: {
        title: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        gender: "",
        insuredType: "",
        email: "",
        dateOfBirth: "",
        address: "",
        occupation: "",
        stateOfResidence: "",
        meansOfId: "",
        idNumber: "",
        mktStaff: "",
        mktStaffID: "",
        startDate: "",
        coverScope: "",
        lga: "",
        duration: "",
        transtype: "",
        conveyance: "",
        sumInsured: "",
        premium: 0,
        premiumRate: 0,
        typeofCover: "",
        endorsement: "",
        submittedBy: "Marine Portal",
        tranrsactionreference: "",
        from: "",
        to: "",
        exRate: "",
        exCurrency: "",
        cargoDescription: "",
        voyageType: "",
        parkingType: "",
        invoicedValue: "",
        excess: "",
        certificateType: "",
        endorsementOptions: "",
        tin: "",
        proformaInvoiceNo: "",
        vessel: "",
        lienClause: "",
        natureofCargo: "",
      },
    };
  },
  computed: {
    ...mapState(useMarineStore, [
      "loading",
      "states",
      "componentLoading",
      "lgas",
      "coverScopes",
      "endorsementOptions",
      "certificateTypes",
      "marketers",
      "currencyRates",
      "steps",
    ]),
    ...mapState(useAdminStore, [
      "coverTypes",
      "natureOfCargos",
      "conveyances",
      "voyageTypes",
      "parkingTypes",
    ]),
    ...mapState(useMainStore, ["user"]),
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getStates",
      "getLga",
      "getCoverScopes",
      "getEndorsementOptions",
      "getCertificateTypes",
      "getMarketers",
      "getCurrency",
      "buyMarine",
    ]),
    ...mapActions(useAdminStore, [
      "getAllConveyances",
      "getAllNatureOfCargos",
      "getAllCoverTypes",
      "getAllParkingTypes",
      "getAllVoyageTypes",
    ]),

    getLgas(id) {
      this.data.stateOfResidence = this.state.name;
      this.getLga(id);
    },

    addMarketeer(marketeer) {
      this.data.mktStaff = marketeer.name;
      this.data.mktStaffID = marketeer.id.toString();
    },

    setCurrency(currency) {
      this.data.exCurrency = currency.exCurrency;
      this.data.exRate = parseFloat(currency.rates);
    },

    async handleSubmit() {
      const res = await this.buyMarine(this.data);
      if (res.isSuccessful) {
        this.$router.push(`/broker/viewBusiness/${res.certificateNo}`);
      }
    },
  },

  created() {
    if (this.user.role !== "Broker") {
      this.$router.go(-1);
    }
    this.getStates();
    this.getCoverScopes();
    this.getEndorsementOptions();
    this.getMarketers();
    this.getCertificateTypes();
    this.getCurrency();
    this.getAllConveyances();
    this.getAllCoverTypes();
    this.getAllNatureOfCargos();
    this.getAllParkingTypes();
    this.getAllVoyageTypes();
  },
};
</script>